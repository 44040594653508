import { Box, Button, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import React, { useCallback, useState } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import MicrostepCard, { MicrostepState } from '../../MicrostepCard'
import {
    GraphQlMicrostep,
    MicrostepRemovalReason
} from '../../../../../graphql/generated/autogenerated'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'

interface MicrostepDialogDetailsProps {
    microstep: GraphQlMicrostep
    onClose: () => void
    onRemove: (reason: MicrostepRemovalReason) => void
    onPreventRemoval: () => void
}

const messages = defineMessages({
    notInterested: {
        defaultMessage: "I'm not interested",
        description: 'button description for removal reasoning'
    },
    nowAHabit: {
        defaultMessage: 'It is now my habit',
        description: 'button description for removal reasoning'
    }
})

const MicrostepDialogConfirm: React.FC<MicrostepDialogDetailsProps> = ({
    microstep,
    onClose,
    onRemove,
    onPreventRemoval
}) => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const [microstepRemoved, setMicrostepRemoved] = useState<boolean>(false)

    const buttonStyling = {
        width: '50%',
        maxWidth: '100%',
        height: theme.spacing(7.25),
        backgroundColor: `${theme.palette.primary.contrastText} !important`,
        borderWidth: '2px !important',
        borderRadius: '8px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            mx: 0,
            mb: theme.spacing(2)
        }
    }
    const onRemoveReasonHandler = useCallback(
        async (reason: MicrostepRemovalReason) => {
            setMicrostepRemoved(true)
            onRemove(reason)
        },
        [setMicrostepRemoved, onRemove]
    )

    const onUndoHandler = useCallback(async () => {
        setMicrostepRemoved(false)
        onPreventRemoval()
    }, [setMicrostepRemoved, onPreventRemoval])

    return (
        <Box data-testid="microstep-dialog-2-confirm">
            <Box sx={{ pb: theme.spacing(2) }}>
                <Typography variant="h3">
                    <FormattedMessage
                        defaultMessage="Why do you want to remove this Microstep?"
                        description="Removal Title"
                    />
                </Typography>
            </Box>
            <Box>
                <MicrostepCard
                    microstep={microstep}
                    state={MicrostepState.Summary}
                />
            </Box>
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'flex-start'}
                sx={{
                    mt: theme.spacing(5),
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column'
                    }
                }}
            >
                {!microstepRemoved && (
                    <>
                        <Button
                            color="primary"
                            variant="outlined"
                            sx={{ mr: theme.spacing(1), ...buttonStyling }}
                            onClick={() =>
                                onRemoveReasonHandler(
                                    MicrostepRemovalReason.NotInterested
                                )
                            }
                        >
                            <CoreTypography customVariant={'buttonLarge'}>
                                {formatMessage(messages.notInterested)}
                            </CoreTypography>
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            sx={{ ml: theme.spacing(1), ...buttonStyling }}
                            onClick={() =>
                                onRemoveReasonHandler(
                                    MicrostepRemovalReason.Accomplished
                                )
                            }
                        >
                            <CoreTypography customVariant={'buttonLarge'}>
                                {formatMessage(messages.nowAHabit)}
                            </CoreTypography>
                        </Button>
                    </>
                )}
                {microstepRemoved && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            delay: 0,
                            duration: 1,
                            bounce: 0,
                            type: 'spring',
                            stiffness: 50
                        }}
                    >
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            sx={{
                                flexDirection: 'row',
                                [theme.breakpoints.down('sm')]: {
                                    flexDirection: 'column'
                                }
                            }}
                        >
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{
                                    delay: 0,
                                    duration: 3,
                                    bounce: 0,
                                    type: 'spring',
                                    stiffness: 33
                                }}
                            >
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    minHeight={theme.spacing(7.25)}
                                    sx={{
                                        mr: theme.spacing(3),
                                        [theme.breakpoints.down('sm')]: {
                                            mr: theme.spacing(3.8125)
                                        }
                                    }}
                                >
                                    <LeafIcon
                                        icon={'check'}
                                        color={'accent'}
                                        sx={{ mr: 1 }}
                                    />
                                    <Typography
                                        variant={'h5'}
                                        color="accent.main"
                                    >
                                        <FormattedMessage
                                            defaultMessage="Microstep Removed"
                                            description="Success message when microstep has been removed"
                                        />
                                    </Typography>
                                </Box>
                            </motion.div>
                            <Button
                                disableRipple
                                variant="text"
                                color="primary"
                                size="large"
                                onClick={onUndoHandler}
                                sx={{
                                    mt: 0,
                                    '&.MuiButtonBase-root:hover': {
                                        bgcolor: 'transparent'
                                    },
                                    [theme.breakpoints.down('sm')]: {
                                        mt: theme.spacing(2)
                                    }
                                }}
                            >
                                <CoreTypography
                                    customVariant="buttonNormal"
                                    sx={{ whiteSpace: 'nowrap' }}
                                >
                                    <FormattedMessage
                                        defaultMessage="Undo"
                                        description="Undo remove microstep"
                                    />
                                </CoreTypography>
                            </Button>
                        </Box>
                    </motion.div>
                )}
            </Box>
        </Box>
    )
}

export default MicrostepDialogConfirm

import {
    ComponentType,
    lazy,
    type LazyExoticComponent,
    Suspense,
    type ComponentProps
} from 'react'
// Todo: Remove this export all and export only the components that are needed.
export * from './components'

/**
 * Wrap a component in a Suspense component with a null fallback.
 * We do this in order to avoid loading all components at once.
 * Downstream apps may only need one or two components from Today, and this ensures that only the necessary components are loaded.
 * @param Component - The component to wrap.
 * @returns The wrapped component.
 */
function wrapComponentInSuspense<T extends ComponentType<any>>(
    Component: LazyExoticComponent<T>
) {
    return (props: ComponentProps<T>): JSX.Element => (
        <Suspense fallback={null}>
            <Component {...props} />
        </Suspense>
    )
}

export const SchedulePopup = wrapComponentInSuspense(
    lazy(() => import('../components/features/MicrostepNew/SchedulePopup'))
)

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { type Slice } from '@reduxjs/toolkit/src/createSlice'

export class todayAnnouncement {
    title: string
    description: string
    buttonText: string
    buttonLink: string
    date: string
}

export interface TodaySlice {
    seenAnnouncements: { [key: string]: todayAnnouncement }
}

const initialState: TodaySlice = {
    seenAnnouncements: {}
}

export const todaySlice: Slice = createSlice({
    name: 'today',
    initialState,
    reducers: {
        addSeenAnnouncement: (
            state,
            { payload }: PayloadAction<todayAnnouncement>
        ) => {
            const date = payload.date
            state.seenAnnouncements[date] = payload
        }
    }
})

export const { addSeenAnnouncement } = todaySlice.actions as any

export default todaySlice.reducer
